<template>
  <section id="finish_scene">

    <transition name="fade">
      <div class="video_cover"></div>
    </transition>

    <transition name="scale-fade">
      <div class="intro_modal" v-if="showModal">
        <div class="inner">
          <h1>しゅうりょう！</h1>
          <p class="text">おつかれさまでした！<br>きょうは{{score}}ポイントだったよ。</p>
          <a href="javascript:void(0);" class="basic_btn" @click="go">HOME</a>
        </div>
      </div>
    </transition>

  </section>
</template>

<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import sleep from '../utils/Sleep'
import {isMobile} from '../pose/demo_util'

let Component = Vue.extend({

  props: {
  },

  data() {
    return {
      showModal: false,
      score: this.$store.state.score,
    }
  },

  components: {
  },

  created() {
  },

  mounted() {
    this.showModal = true
  },

  methods: {
    go(){
      location.href = "/"
    }
  }
})

export default Component
</script>
