var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "countdown_scene" } }, [
    _c(
      "div",
      { staticClass: "countdown" },
      [
        _c("transition", { attrs: { name: "scale-fade" } }, [
          _vm.count == 5
            ? _c("div", { staticClass: "inner" }, [
                _c("span", [_vm._v(_vm._s(_vm.count))])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "scale-fade" } }, [
          _vm.count == 4
            ? _c("div", { staticClass: "inner" }, [
                _c("span", [_vm._v(_vm._s(_vm.count))])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "scale-fade" } }, [
          _vm.count == 3
            ? _c("div", { staticClass: "inner" }, [
                _c("span", [_vm._v(_vm._s(_vm.count))])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "scale-fade" } }, [
          _vm.count == 2
            ? _c("div", { staticClass: "inner" }, [
                _c("span", [_vm._v(_vm._s(_vm.count))])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "scale-fade" } }, [
          _vm.count == 1
            ? _c("div", { staticClass: "inner" }, [
                _c("span", [_vm._v(_vm._s(_vm.count))])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "scale-fade" } }, [
          _vm.count == 0
            ? _c("div", { staticClass: "inner" }, [
                _c("span", [_vm._v(_vm._s(_vm.count))])
              ])
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }