var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "opening_scene" } }, [
    _c("div", { staticClass: "cover_title" }, [
      _c("div", { staticClass: "inner" }, [
        _c("h1", [_vm._v("POSENET EXERCISE")]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "basic_btn wt",
            attrs: { href: "javascript:void(0);" },
            on: { click: _vm.go }
          },
          [_vm._v("スタート")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "link wt",
            attrs: { href: "javascript:void(0);" },
            on: { click: _vm.goManual }
          },
          [_vm._v("カメラを指定してスタート")]
        ),
        _vm._v(" "),
        _vm.errorMessage
          ? _c("p", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.errorMessage))
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }