import * as posenet from '@tensorflow-models/posenet'
import {drawBoundingBox, drawKeypoints, drawSkeleton, isMobile, toggleLoadingUI, tryResNetButtonName, tryResNetButtonText, updateTryResNetButtonDatGuiCss} from './demo_util';

let minPoseConfidence
let minPartConfidence

class PoseMain {

  constructor(video) {
    this.video = video
  }

  async loadArchitecture(guiState){
    return new Promise(async resolve => {
      this.net = await posenet.load({
        architecture: guiState.input.architecture,
        outputStride: guiState.input.outputStride,
        inputResolution: guiState.input.inputResolution,
        multiplier: guiState.input.multiplier,
        quantBytes: guiState.input.quantBytes
      })
      resolve(true)
    })
  }

  async update(guiState){
    let poses = []

    switch (guiState.algorithm) {

      case 'single-pose':
        const pose = await this.net.estimatePoses(video, {
          flipHorizontal: guiState.flipHorizontal,
          decodingMethod: 'single-person'
        })
        poses = poses.concat(pose)
        minPoseConfidence = +guiState.singlePoseDetection.minPoseConfidence
        minPartConfidence = +guiState.singlePoseDetection.minPartConfidence
        break

      case 'multi-pose':
        let all_poses = await this.net.estimatePoses(video, {
          flipHorizontal: guiState.flipHorizontal,
          decodingMethod: 'multi-person',
          maxDetections: guiState.multiPoseDetection.maxPoseDetections,
          scoreThreshold: guiState.multiPoseDetection.minPartConfidence,
          nmsRadius: guiState.multiPoseDetection.nmsRadius
        })

        poses = poses.concat(all_poses)
        minPoseConfidence = +guiState.multiPoseDetection.minPoseConfidence
        minPartConfidence = +guiState.multiPoseDetection.minPartConfidence
        break
    }
    return {
      poses: poses,
      minPoseConfidence: minPoseConfidence,
      minPartConfidence: minPartConfidence
    }
  }

}

export default PoseMain
