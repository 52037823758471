var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "pose_detector" } },
    [
      _c(
        "div",
        { staticClass: "setting" },
        [
          _c("p", { staticClass: "enemy" }, [
            _vm._v("ball(transparent): " + _vm._s(_vm.gameData.effectCount))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "enemy" }, [
            _vm._v("ball: " + _vm._s(_vm.gameData.enemyCount))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "score" }, [
            _vm._v("score: " + _vm._s(_vm.gameData.score))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "logic" }, [
            _vm._v(_vm._s(_vm.guiState.input.architecture))
          ]),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-1",
                  modifiers: { "modal-1": true }
                }
              ],
              staticClass: "setting_btn"
            },
            [_vm._v("Setting")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.guiState.showFps,
            expression: "guiState.showFps"
          }
        ],
        ref: "statsBox",
        staticClass: "statsBox"
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        { attrs: { id: "modal-1", title: "PoseNet Setting", "ok-only": "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { "label-size": "sm", label: "Algorithm:" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          size: "sm",
                          options: _vm.guiOptions.algorithm
                        },
                        model: {
                          value: _vm.guiState.algorithm,
                          callback: function($$v) {
                            _vm.$set(_vm.guiState, "algorithm", $$v)
                          },
                          expression: "guiState.algorithm"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pt-2 pb-2" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { size: "md", switch: "" },
                          model: {
                            value: _vm.guiState.flipHorizontal,
                            callback: function($$v) {
                              _vm.$set(_vm.guiState, "flipHorizontal", $$v)
                            },
                            expression: "guiState.flipHorizontal"
                          }
                        },
                        [_c("small", [_vm._v("FlipHorizontal")])]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pt-2 pb-2" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { size: "md", switch: "" },
                          model: {
                            value: _vm.guiState.showFps,
                            callback: function($$v) {
                              _vm.$set(_vm.guiState, "showFps", $$v)
                            },
                            expression: "guiState.showFps"
                          }
                        },
                        [_c("small", [_vm._v("ShowFps")])]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-size": "sm",
                        label: "throttle: " + _vm.guiState.throttle
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { type: "range", min: "1", max: "4", step: "1" },
                        model: {
                          value: _vm.guiState.throttle,
                          callback: function($$v) {
                            _vm.$set(_vm.guiState, "throttle", $$v)
                          },
                          expression: "guiState.throttle"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-2" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.collapse-input",
                              modifiers: { "collapse-input": true }
                            }
                          ],
                          staticClass: "text-left",
                          attrs: {
                            size: "sm",
                            block: "",
                            variant: "light",
                            "aria-expanded": "false",
                            "aria-controls": "collapse-input"
                          }
                        },
                        [_vm._v("Input")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        { staticClass: "p-3", attrs: { id: "collapse-input" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                label: "Architecture:"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  size: "sm",
                                  options: _vm.guiOptions.input.architecture
                                },
                                model: {
                                  value: _vm.guiState.input.architecture,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.guiState.input,
                                      "architecture",
                                      $$v
                                    )
                                  },
                                  expression: "guiState.input.architecture"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                label: "OutputStride:"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  size: "sm",
                                  options: _vm.guiOptions.input.outputStride
                                },
                                model: {
                                  value: _vm.guiState.input.outputStride,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.guiState.input,
                                      "outputStride",
                                      $$v
                                    )
                                  },
                                  expression: "guiState.input.outputStride"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                label: "InternalResolution:"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  size: "sm",
                                  options: _vm.guiOptions.input.inputResolution
                                },
                                model: {
                                  value: _vm.guiState.input.inputResolution,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.guiState.input,
                                      "inputResolution",
                                      $$v
                                    )
                                  },
                                  expression: "guiState.input.inputResolution"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                label: "Multiplier:"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  size: "sm",
                                  options: _vm.guiOptions.input.multiplier
                                },
                                model: {
                                  value: _vm.guiState.input.multiplier,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.guiState.input,
                                      "multiplier",
                                      $$v
                                    )
                                  },
                                  expression: "guiState.input.multiplier"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                label: "QuantBytes:"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  size: "sm",
                                  options: _vm.guiOptions.input.quantBytes
                                },
                                model: {
                                  value: _vm.guiState.input.quantBytes,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.guiState.input,
                                      "quantBytes",
                                      $$v
                                    )
                                  },
                                  expression: "guiState.input.quantBytes"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-2" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName:
                                "v-b-toggle.collapse-singlePoseDetection",
                              modifiers: {
                                "collapse-singlePoseDetection": true
                              }
                            }
                          ],
                          staticClass: "text-left",
                          attrs: {
                            size: "sm",
                            block: "",
                            variant: "light",
                            "aria-expanded": "false",
                            "aria-controls": "collapse-singlePoseDetection"
                          }
                        },
                        [_vm._v("SinglePoseDetection")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          staticClass: "p-3",
                          attrs: { id: "collapse-singlePoseDetection" }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                label:
                                  "minPoseConfidence: " +
                                  _vm.guiState.singlePoseDetection
                                    .minPoseConfidence
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "range",
                                  min: "0.0",
                                  max: "1.0",
                                  step: "0.01"
                                },
                                model: {
                                  value:
                                    _vm.guiState.singlePoseDetection
                                      .minPoseConfidence,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.guiState.singlePoseDetection,
                                      "minPoseConfidence",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "guiState.singlePoseDetection.minPoseConfidence"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                label:
                                  "minPartConfidence: " +
                                  _vm.guiState.singlePoseDetection
                                    .minPartConfidence
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "range",
                                  min: "0.0",
                                  max: "1.0",
                                  step: "0.01"
                                },
                                model: {
                                  value:
                                    _vm.guiState.singlePoseDetection
                                      .minPartConfidence,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.guiState.singlePoseDetection,
                                      "minPartConfidence",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "guiState.singlePoseDetection.minPartConfidence"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-2" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.collapse-multiPoseDetection",
                              modifiers: { "collapse-multiPoseDetection": true }
                            }
                          ],
                          staticClass: "text-left",
                          attrs: {
                            size: "sm",
                            block: "",
                            variant: "light",
                            "aria-expanded": "false",
                            "aria-controls": "collapse-multiPoseDetection"
                          }
                        },
                        [_vm._v("MultiPoseDetection")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          staticClass: "p-3",
                          attrs: { id: "collapse-multiPoseDetection" }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                label:
                                  "maxPoseDetections: " +
                                  _vm.guiState.multiPoseDetection
                                    .maxPoseDetections
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "range", min: "1", max: "20" },
                                model: {
                                  value:
                                    _vm.guiState.multiPoseDetection
                                      .maxPoseDetections,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.guiState.multiPoseDetection,
                                      "maxPoseDetections",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "guiState.multiPoseDetection.maxPoseDetections"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                label:
                                  "minPoseConfidence: " +
                                  _vm.guiState.multiPoseDetection
                                    .minPoseConfidence
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "range",
                                  min: "0.0",
                                  max: "1.0",
                                  step: "0.01"
                                },
                                model: {
                                  value:
                                    _vm.guiState.multiPoseDetection
                                      .minPoseConfidence,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.guiState.multiPoseDetection,
                                      "minPoseConfidence",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "guiState.multiPoseDetection.minPoseConfidence"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                label:
                                  "minPartConfidence: " +
                                  _vm.guiState.multiPoseDetection
                                    .minPartConfidence
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "range",
                                  min: "0.0",
                                  max: "1.0",
                                  step: "0.01"
                                },
                                model: {
                                  value:
                                    _vm.guiState.multiPoseDetection
                                      .minPartConfidence,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.guiState.multiPoseDetection,
                                      "minPartConfidence",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "guiState.multiPoseDetection.minPartConfidence"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                label:
                                  "nmsRadius: " +
                                  _vm.guiState.multiPoseDetection.nmsRadius
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "range", min: "0", max: "40" },
                                model: {
                                  value:
                                    _vm.guiState.multiPoseDetection.nmsRadius,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.guiState.multiPoseDetection,
                                      "nmsRadius",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "guiState.multiPoseDetection.nmsRadius"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-2" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.collapse-output",
                              modifiers: { "collapse-output": true }
                            }
                          ],
                          staticClass: "text-left",
                          attrs: {
                            size: "sm",
                            block: "",
                            variant: "light",
                            "aria-expanded": "false",
                            "aria-controls": "collapse-output"
                          }
                        },
                        [_vm._v("output")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          staticClass: "p-3",
                          attrs: { id: "collapse-output" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pt-2 pb-2" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { size: "md", switch: "" },
                                  model: {
                                    value: _vm.guiState.output.showVideo,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.guiState.output,
                                        "showVideo",
                                        $$v
                                      )
                                    },
                                    expression: "guiState.output.showVideo"
                                  }
                                },
                                [_c("small", [_vm._v("showVideo")])]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "pt-2 pb-2" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { size: "md", switch: "" },
                                  model: {
                                    value: _vm.guiState.output.showSkeleton,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.guiState.output,
                                        "showSkeleton",
                                        $$v
                                      )
                                    },
                                    expression: "guiState.output.showSkeleton"
                                  }
                                },
                                [_c("small", [_vm._v("showSkeleton")])]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "pt-2 pb-2" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { size: "md", switch: "" },
                                  model: {
                                    value: _vm.guiState.output.showPoints,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.guiState.output,
                                        "showPoints",
                                        $$v
                                      )
                                    },
                                    expression: "guiState.output.showPoints"
                                  }
                                },
                                [_c("small", [_vm._v("showPoints")])]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "pt-2 pb-2" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { size: "md", switch: "" },
                                  model: {
                                    value: _vm.guiState.output.showBoundingBox,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.guiState.output,
                                        "showBoundingBox",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "guiState.output.showBoundingBox"
                                  }
                                },
                                [_c("small", [_vm._v("showBoundingBox")])]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: { "label-size": "sm", label: "Save Parameters:" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary", size: "sm" },
                          on: { click: _vm.saveLocalStorage }
                        },
                        [_vm._v("Save")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "danger", size: "sm" },
                          on: { click: _vm.deleteLocalStorage }
                        },
                        [_vm._v("Delete")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }