<template>
  <section id="opening_scene">

    <div class="cover_title">
      <div class="inner">
        <h1>POSENET EXERCISE</h1>
        <a href="javascript:void(0);" class="basic_btn wt" @click="go">スタート</a>
        <a href="javascript:void(0);" class="link wt" @click="goManual">カメラを指定してスタート</a>
        <p v-if="errorMessage" class="error">{{errorMessage}}</p>
      </div>
    </div>

  </section>
</template>

<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import sound from '../utils/Sound'
import {isMobile} from '../pose/demo_util'

let Component = Vue.extend({

  props: {
  },

  data() {
    return {
      cameraStatus: this.$store.state.cameraStatus,
      errorMessage: ''
    }
  },

  components: {
  },

  created() {
  },

  mounted() {
    if(this.cameraStatus == 'CAMERA_DISABLE'){
      this.errorMessage = 'カメラを許可して下さい'
    }
  },

  methods: {
    go(){
      sound.playBtnSE()
      this.$router.push({path: '/intro'})
    },

    goManual(){
      this.$router.push({path: '/intro', query: {manual: true}})
    }
  }
})

export default Component
</script>
