import { render, staticRenderFns } from "./FinishScene.vue?vue&type=template&id=5bdb53ec&"
import script from "./FinishScene.vue?vue&type=script&lang=js&"
export * from "./FinishScene.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\hisah\\Projects\\KidsSports\\SFCKids-First-PoC\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5bdb53ec')) {
      api.createRecord('5bdb53ec', component.options)
    } else {
      api.reload('5bdb53ec', component.options)
    }
    module.hot.accept("./FinishScene.vue?vue&type=template&id=5bdb53ec&", function () {
      api.rerender('5bdb53ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/scenes/FinishScene.vue"
export default component.exports