<template>
  <header id="head">
  </header>
</template>

<script>
import Vue from 'vue'
import Utils from '../utils/Utils'
import UserAgant from '../utils/UserAgant'
const $ = require('jquery')

let Component = Vue.extend({

  mixins: [Utils],

  props: {
  },

  data() {
    return {
      isHome: false,
    }
  },

  components: {
  },

  created() {
  },

  mounted() {
  },

  destroyed() {
  },

  computed: {
  },

  methods: {
  }
})

export default Component
</script>
