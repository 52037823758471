var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "playing_scene" }, on: { click: _vm.togglePause } },
    [
      _c("div", { staticClass: "ui_score" }, [
        _c("p", { staticClass: "text" }, [_vm._v("とくてん")]),
        _vm._v(" "),
        _c("p", { staticClass: "num" }, [_vm._v(_vm._s(_vm.scoreView))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ui_time" }, [
        _c("p", { staticClass: "text" }, [_vm._v("のこりじかん")]),
        _vm._v(" "),
        _c("p", {
          staticClass: "gage",
          style: { width: (1 - _vm.currentTimePer) * 100 + "%" }
        })
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isPause ? _c("div", { staticClass: "video_cover" }) : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }