<template>
  <section id="intro_scene">

    <transition name="fade">
      <div class="video_cover" v-if="showModal"></div>
    </transition>

    <transition name="fade">
      <div class="guide_cover landscape" v-if="showModal"></div>
    </transition>

    <transition name="scale-fade" mode="out-in" appear>
      <b-modal id="modal-center" centered title="Select Camera" v-model="showSelect" ok-only
      @hidden="hiddenSelectCamera"
      @ok="okSelectCamera">
        <b-row>
          <b-col cols="12">

          <b-form-group label-size="lg" label="Camera:">
            <b-form-select size="lg" v-model="cameraId" :options="cameraOptions"></b-form-select>
          </b-form-group>

          </b-col>
        </b-row>
      </b-modal>
    </transition>

    <transition name="scale-fade" mode="out-in" appear>
      <div class="intro_modal" v-if="showModal&&showIntro1">
        <div class="inner">
          <h1>じゅんびしよう</h1>
          <p class="text">しるえっとにからだをあわせよう</p>
          <a href="javascript:void(0);" class="basic_btn" @click="next">NEXT</a>
        </div>
      </div>
    </transition>

    <transition name="scale-fade" mode="out-in" appear>
      <div class="intro_modal" v-if="showModal&&showIntro2">
        <div class="inner">
          <h1>あそびかた</h1>
          <p class="text">あおいまるをさわって<br>とくてんをふやそう！</p>
          <a href="javascript:void(0);" class="basic_btn" @click="go">GO</a>
        </div>
      </div>
    </transition>

  </section>
</template>

<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import sleep from '../utils/Sleep'
import sound from '../utils/Sound'
import {isMobile} from '../pose/demo_util'

let Component = Vue.extend({

  props: {
  },

  data() {
    return {
      // videoWidth: 1280,
      // videoHeight: 720,
      videoWidth: 640,
      videoHeight: 480,
      isManual: false,
      cameraList: [],
      cameraOptions: [],
      cameraId: '',
      showSelect: false,
      showModal: false,
      showIntro1: false,
      showIntro2: false
    }
  },

  components: {
  },

  created() {
  },

  async mounted() {

    this.playBGM()

    this.isManual = (this.$route.query.manual)? true : false
    if(this.isManual){
      this.manualStart()
    }
    else{
      this.autoStart()
    }

  },

  methods: {

    playBGM(){
      sound.playBGM()
    },

    async autoStart(){
      this.cameraSetup()
    },

    async manualStart(){
      this.cameraList = await this.getDevices()

      if(this.cameraList.length > 0){
        if(this.cameraList.length == 1){
          this.cameraSetup()
        }
        else{
          this.selectCamera()
        }
      }
      else{
        this.$store.commit('setCameraStatus', 'CAMERA_NOTFOUND')
        this.$router.push({path: '/'})
      }
    },

    async getDevices(){
      return new Promise((resolve, reject) => {

        navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          // success
          let cameras = []
          devices.forEach((device) => {
            // console.log(device)
            if(device.kind == 'videoinput'){
              cameras.push(device)
            }
          })
          resolve(cameras)
        })
        .catch((error) => {
          // error
          console.error('enumerateDevide ERROR:', error)
          reject(error)
        })

      })
    },

    selectCamera(){
      this.cameraOptions.length = 0

      this.cameraList.forEach((camera) => {
        this.cameraOptions.push({value: camera.deviceId, text: camera.label})
      })
      this.cameraId = this.cameraOptions[0].value
      
      this.showSelect = true
    },

    hiddenSelectCamera(){
      console.log('hiddenSelectCamera')
      this.completeSelectCamera()
    },

    okSelectCamera(){
      // console.log('okSelectCamera')
      // this.completeSelectCamera()
    },

    completeSelectCamera(){
      console.log('completeSelectCamera')
      console.log('selected camera', this.cameraId)
      this.showSelect = false
      this.cameraSetup()
    },

    async cameraSetup(){
      try{
        const data = await this.getCameraMedia()

        // set store
        this.$store.commit('setVideo', data.video)
        this.$store.commit('setVideoSettings', data.settings)

        this.startPoseDetection()
      }
      catch(error){
        console.error('catch', error)
        this.$store.commit('setCameraStatus', 'CAMERA_DISABLE')
        this.$router.push({path: '/'})
      }
    },
    
    async getCameraMedia(){
      /* TODO: 2回目の読み込みどうにかしたい */
      const video = document.getElementById('video')

      const mobile = isMobile()
      
      return new Promise((resolve, reject) => {

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          let error = 'Browser API navigator.mediaDevices.getUserMedia not available'
          reject(error)
        }
        else{
          let videoParams = {
            width: mobile ? undefined : this.videoWidth,
            height: mobile ? undefined : this.videoHeight
          }

          if(this.isManual){
            videoParams['deviceId'] = (this.cameraId != '')? this.cameraId : null
          }
          else{
            videoParams['facingMode'] = 'user'
          }

          let constratints, capabilities, settings

          navigator.mediaDevices.getUserMedia({
            video: videoParams,
            audio: false
          })
          .then(function (stream){
            video.srcObject = stream

            const [track] = stream.getVideoTracks()

            // 1. どういう指定でgUMされたか
            constratints = track.getConstraints()

            // 2. このデバイスで指定できる値の範囲
            capabilities = track.getCapabilities()

            // 3. 最終的にどんな指定になったか
            settings = track.getSettings()

            console.log('constratints', constratints)
            console.log('capabilities', capabilities)
            console.log('settings', settings)
          })
          .catch(function (error){
            reject(error)
          })

          video.onloadedmetadata = () => {
            resolve({'video': video, 'settings': settings})
          }
        }
      })
    },

    async startPoseDetection(){
      console.log('startPoseDetection')
      await this.$emit('scene-callback', 'intro')

      this.showModal = true
      this.showIntro1 = true

      await sleep(5000)

      if(this.showIntro1) this.next()
    },

    async next(){
      sound.playBtnSE()
      
      this.showIntro1 = false
      this.showIntro2 = true

      await sleep(5000)

      if(this.showIntro2) this.go()
    },

    async go(){
      sound.playBtnSE()
      
      this.showIntro = false
      this.showIntro2 = false

      await sleep(1000)
      this.$router.push({path: '/countdown'})
    }
  }
})

export default Component
</script>
