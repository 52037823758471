<template>
  <section id="countdown_scene">
    
    <div class="countdown">

      <transition name="scale-fade">
        <div class="inner" v-if="count == 5"><span>{{count}}</span></div>
      </transition>

      <transition name="scale-fade">
        <div class="inner" v-if="count == 4"><span>{{count}}</span></div>
      </transition>

      <transition name="scale-fade">
        <div class="inner" v-if="count == 3"><span>{{count}}</span></div>
      </transition>

      <transition name="scale-fade">
        <div class="inner" v-if="count == 2"><span>{{count}}</span></div>
      </transition>

      <transition name="scale-fade">
        <div class="inner" v-if="count == 1"><span>{{count}}</span></div>
      </transition>

      <transition name="scale-fade">
        <div class="inner" v-if="count == 0"><span>{{count}}</span></div>
      </transition>

    </div>

  </section>
</template>

<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import sleep from '../utils/Sleep'
import {isMobile} from '../pose/demo_util'

let Component = Vue.extend({

  props: {
  },

  data() {
    return {
      count: 5
    }
  },

  components: {
  },

  created() {
  },

  mounted() {
    this.start()
  },

  methods: {
    async start(){
      this.count = 5
      await sleep(1000)
      this.count = 4
      await sleep(1000)
      this.count = 3
      await sleep(1000)
      this.count = 2
      await sleep(1000)
      this.count = 1
      await sleep(1000)
      this.count = 0
      await sleep(1000)
      this.$router.push({path: '/playing'})
    }
  }
})

export default Component
</script>
