var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "intro_scene" } },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showModal ? _c("div", { staticClass: "video_cover" }) : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showModal
          ? _c("div", { staticClass: "guide_cover landscape" })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "scale-fade", mode: "out-in", appear: "" } },
        [
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-center",
                centered: "",
                title: "Select Camera",
                "ok-only": ""
              },
              on: { hidden: _vm.hiddenSelectCamera, ok: _vm.okSelectCamera },
              model: {
                value: _vm.showSelect,
                callback: function($$v) {
                  _vm.showSelect = $$v
                },
                expression: "showSelect"
              }
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { "label-size": "lg", label: "Camera:" } },
                        [
                          _c("b-form-select", {
                            attrs: { size: "lg", options: _vm.cameraOptions },
                            model: {
                              value: _vm.cameraId,
                              callback: function($$v) {
                                _vm.cameraId = $$v
                              },
                              expression: "cameraId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "scale-fade", mode: "out-in", appear: "" } },
        [
          _vm.showModal && _vm.showIntro1
            ? _c("div", { staticClass: "intro_modal" }, [
                _c("div", { staticClass: "inner" }, [
                  _c("h1", [_vm._v("じゅんびしよう")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text" }, [
                    _vm._v("しるえっとにからだをあわせよう")
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "basic_btn",
                      attrs: { href: "javascript:void(0);" },
                      on: { click: _vm.next }
                    },
                    [_vm._v("NEXT")]
                  )
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "scale-fade", mode: "out-in", appear: "" } },
        [
          _vm.showModal && _vm.showIntro2
            ? _c("div", { staticClass: "intro_modal" }, [
                _c("div", { staticClass: "inner" }, [
                  _c("h1", [_vm._v("あそびかた")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text" }, [
                    _vm._v("あおいまるをさわって"),
                    _c("br"),
                    _vm._v("とくてんをふやそう！")
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "basic_btn",
                      attrs: { href: "javascript:void(0);" },
                      on: { click: _vm.go }
                    },
                    [_vm._v("GO")]
                  )
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }