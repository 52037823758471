import * as PIXI from 'pixi.js'
import * as posenet from '@tensorflow-models/posenet'
import * as tf from '@tensorflow/tfjs'
import {isMobile} from '../pose/demo_util'
import sound from '../utils/Sound'

const mobile = isMobile()
let ballColor = 0x0066ff
let bodyColliderColor = 0xffcc66
let bodyColliderBase = 60

class GameCore {

  constructor(video, videoSettings) {

    this.video = video
    this.settings = videoSettings
    this.ww = window.innerWidth
    this.wh = window.innerHeight
    this.ratio = 1.0
    this.marginLeft = 0
    this.marginTop = 0
    this.isPortrait = false
    let option = {
      width: this.ww,
      height: this.wh,
      resolution: window.devicePixelRatio || 1,
      autoResize: true
    }

    this.app = new PIXI.Application(option)

    const container = document.getElementById('pixi_container')
    container.appendChild(this.app.view)
    this.app.view.setAttribute('id', 'canvas')

    this.isGameActive = false
    this.gameData = {
      score: 0,
      enemyCount: 0,
      effectCount: 0
    }

    // caliculator
    this.bodyColliderSize = bodyColliderBase
    this.collisions = []
    this.boundingAreas = []
  
    // container and sprite
    this.videoCont = this.createVideoSprite(video)
    this.videoSpr = this.videoCont.children[0]
    console.log('constructor: videoCont', this.videoCont)
    console.log('constructor: videoSpr', this.videoSpr)

    this.skeletonSpr = this.createSkeleton()
    this.skeletonGra = this.skeletonSpr.children[0]
    this.ballCont = this.createballCont()
    this.effectCont = this.createeffectCont()

    // event
    $(window).on('load resize', () => {
      this.resize()
    })

    $(window).on('load orientationchange', (e) => {
      this.orinent()
    }).trigger('orientationchange')
  }
  

  updateVideo(){
    this.resize()
  }


  resize(){
    let ww = window.innerWidth
    let wh = window.innerHeight
    let vw = this.settings.width
    let vh = this.settings.height

    if(this.isPortrait){
      vw = this.settings.height
      vh = this.settings.width
    }

    this.video.width = vw
    this.video.height = vh

    let xRatio = ww / vw
    let yRatio = wh / vh
    this.ratio = Math.max(xRatio, yRatio)

    let fvw = vw * this.ratio
    let fvh = vh * this.ratio

    this.marginLeft = (ww - fvw) / 2
    this.marginTop = (wh - fvh) / 2

    console.log('resize: settings', this.settings)
    console.log('resize: stage', ww, wh)
    console.log('resize: video', vw, vh)
    console.log('resize: ratio', xRatio, yRatio, this.ratio)

    this.app.renderer.resize(ww, wh)
    this.videoCont.scale.set(this.ratio, this.ratio)

    this.videoCont.position.set(this.marginLeft, this.marginTop)

    this.ww = ww
    this.wh = wh
  }


  orinent(){
    if(mobile){
      var angle = screen && screen.orientation && screen.orientation.angle
      if (angle == null) {
        angle = window.orientation || 0
      }
      if(angle % 180 !== 0) {
        console.log('orinent: landscape')
        this.isPortrait = false
      }
      else {
        console.log('orinent: portrait')
        this.isPortrait = true
      }

      this.resize()
    }
  }


  createVideoSprite(video){
    let option = {}
    let texture = PIXI.Texture.from(video, option, false)
    let sprite = new PIXI.Sprite(texture)
    let cont = new PIXI.Container()
    cont.addChild(sprite)
    this.app.stage.addChild(cont)

    console.log('createVideoSprite: sprite size', sprite.width, sprite.height)
    
    return cont
  }


  createSkeleton(){
    let sprite = new PIXI.Sprite()
    let g = new PIXI.Graphics()
    sprite.addChild(g)
    this.app.stage.addChild(sprite)
    
    return sprite
  }


  createballCont(){
    let cont = new PIXI.Container()
    this.app.stage.addChild(cont)

    return cont
  }


  createeffectCont(){
    let cont = new PIXI.Container()
    this.app.stage.addChild(cont)

    return cont
  }


  update(data, guiState){
    // clear graphics
    let g = this.skeletonGra
    g.clear()

    // clear collisions
    this.collisions.length = 0
    this.boundingAreas.length = 0

    // draw
    data.poses.forEach(({score, keypoints}) => {

      if (score >= data.minPoseConfidence) {

        let scale = this.ratio
        let offset = {x: this.marginLeft, y: this.marginTop}

        if (guiState.output.showPoints) {
          this.drawKeypoints(keypoints, data.minPartConfidence, this.ratio, offset)
        }

        if (guiState.output.showSkeleton) {
          this.drawSkeleton(keypoints, data.minPartConfidence, this.ratio, offset)
        }

        if (guiState.output.showBoundingBox) {
          this.drawBoundingBox(keypoints, this.ratio, offset)
        }

      }
    })

    if(this.isGameActive){
      // update ball
      this.updateBalls()

      // collision check
      this.checkCollision()

      // update effect
      this.updateEffects()
    }
    
    // flipHorisontal
    if(guiState.flipHorizontal){
      this.videoSpr.scale.x = -1
      this.videoSpr.position.x = this.videoSpr.width
    }
    else{
      this.videoSpr.scale.x = 1
      this.videoSpr.position.x = 0
    }
    
    // showVideo
    if(guiState.output.showVideo){
      this.videoCont.visible = true
    }
    else{
      this.videoCont.visible = false
    }

    // boundingArea
    let boundingSize = bodyColliderBase
    // if(this.boundingAreas.length > 0){
    //   boundingSize = this.boundingAreas[0] * bodyColliderBase
    // }
    // this.bodyColliderSize = boundingSize

    // console.log('update: boundingAverage', boundingSize, this.boundingAreas.length)

    // render
    this.app.renderer.render(this.app.stage)

    return this.gameData
  }


  addHandKeypoint(elbow, wrist){
    if(elbow.score > 0.3 && wrist.score > 0.3){
      let dist = this.getDistance(elbow.position.x, elbow.position.y, wrist.position.x, wrist.position.y)
      let radian = this.getRadian(elbow.position.x, elbow.position.y, wrist.position.x, wrist.position.y)

      let x = wrist.position.x + Math.cos(radian) * dist * 0.7
      let y = wrist.position.y + Math.sin(radian) * dist * 0.7
      
      return {
        position: {
          x: x,
          y: y
        },
        score: 1.0
      }
    }
    else{
      return {
        position: {
          x: 0,
          y: 0
        },
        score: 0.0
      }
    }
  }


  // 0: nose, 1: leftEye, 2: rightEye, 3: leftEar, 4: rightEar, 5: leftShoulder, 6: rightShoulder, 7: leftElbow, 8: rightElbow, 9: leftWrist, 10: rightWrist, 11: leftHip, 12: rightHip, 13: leftKnee, 14: rightKnee, 15: leftAnkle, 16: rightAnkle

  drawKeypoints(keypoints, minConfidence, scale = 1, offset = {x: 0, y: 0}) {
    
    // create hand keypoint
    let leftElbow = keypoints[7]
    let leftWrist = keypoints[9]
    let rightElbow = keypoints[8]
    let rightWrist = keypoints[10]

    let leftHand = this.addHandKeypoint(leftElbow, leftWrist)
    let rightHand = this.addHandKeypoint(rightElbow, rightWrist)
    let nKeypoints = []

    for (let i = 0; i < keypoints.length; i++) {
      nKeypoints[i] = {}
      nKeypoints[i].score = keypoints[i].score
      nKeypoints[i].position = keypoints[i].position
    }

    nKeypoints.push(leftHand)
    nKeypoints.push(rightHand)
    
    for (let i = 0; i < nKeypoints.length; i++) {
      const keypoint = nKeypoints[i]
  
      if (keypoint.score < minConfidence) {
        continue
      }
  
      const {y, x} = keypoint.position

      let nx = x * scale + offset.x
      let ny = y * scale + offset.y

      // Hand
      if(i == 9 || i == 10 || i == 17 || i == 18){
        this.drawPoint(ny, nx, this.bodyColliderSize, bodyColliderColor)
        this.collisions.push({x: nx, y: ny})
      }
      // Other
      else{
        this.drawPoint(ny, nx, 5, 0xffffff)
      }
    }
  }

  
  drawPoint(y, x, r, color) {
    let g = this.skeletonGra
    g.beginFill(color, 1)
    g.drawCircle(x, y, r)
    g.endFill()
  }


  getDistance(x, y, x2, y2) {
    let distance = Math.sqrt((x2 - x) * (x2 - x) + (y2 - y) * (y2 - y))
    return distance
  }


  getRadian(x, y, x2, y2) {
    let radian = Math.atan2(y2 - y, x2 - x)
    return radian
  }


  drawSkeleton(keypoints, minConfidence, scale = 1, offset = {x: 0, y: 0}) {
    const adjacentKeyPoints = posenet.getAdjacentKeyPoints(keypoints, minConfidence)

    adjacentKeyPoints.forEach((keypoints) => {
      this.drawSegment(
        this.toTuple(keypoints[0].position), 
        this.toTuple(keypoints[1].position), 
        scale, offset)
    })
  }
  

  drawSegment([ay, ax], [by, bx], scale, offset) {
    let g = this.skeletonGra
    g.lineStyle(1, 0xffffff, 1)
    g.moveTo(ax * scale + offset.x, ay * scale + offset.y)
    g.lineTo(bx * scale + offset.x, by * scale + offset.y)
  }


  drawBoundingBox(keypoints, scale = 1, offset = {x: 0, y: 0}) {
    const boundingBox = posenet.getBoundingBox(keypoints)
    
    let minx = boundingBox.minX * scale + offset.x
    let maxx = boundingBox.maxX * scale + offset.x
    let miny = boundingBox.minY * scale + offset.y
    let maxy = boundingBox.maxY * scale + offset.y
  
    let g = this.skeletonGra
    g.lineStyle(2, 0xff0000, 0.5)
    g.drawRect(minx, miny, maxx - minx, maxy - miny)

    // add boundingArea
    let boundingArea = ((maxx - minx) * (maxy - miny)) / (this.ww * this.wh)
    this.boundingAreas.push(boundingArea)
  }


  toTuple({y, x}) {
    return [y, x]
  }


  addBall(){
    console.log('view', this.app.view.width, this.app.view.height)
    console.log('stage', this.app.stage.width, this.app.stage.height)

    let ball = new PIXI.Sprite()
    let margin = 120
    let x = Math.random() * (this.ww - margin * 2) + margin
    let y = Math.random() * (this.wh - margin * 2) + margin
    ball.position.set(x, y)
    
    ball.speed = {
      x: Math.random() * 2 - 1,
      y: Math.random() * 2 - 1,
      radius: Math.random() * 1
    }

    let ballSize = 60
    let ranRadius = Math.random()
    ball.radius = ranRadius * ballSize + ballSize / 2

    ball.life = 100
    if(ranRadius < 0.3){
      ball.life *= 3.0
    }
    else if(ranRadius < 0.6){
      ball.life *= 1.5
    }
    else{
      ball.life *= 1
    }

    // console.log(this.app.view.width, this.app.view.height)
    console.log('addBall:', 'x', x, 'y', y, 'radius', ball.radius)

    let g = new PIXI.Graphics()
    g.beginFill(ballColor, 1)
    g.drawCircle(0, 0, ball.radius)
    g.endFill()
    ball.addChild(g)
    this.ballCont.addChild(ball)

    // update enemy count
    this.gameData.enemyCount = this.ballCont.children.length
  }


  updateBalls(){
    for(let i = 0; i < this.ballCont.children.length; i++){
      let ball = this.ballCont.getChildAt(i)
      ball.position.x += ball.speed.x
      ball.position.y += ball.speed.y
      ball.radius += ball.speed.radius
      ball.life -= 1

      let g = ball.children[0]
      g.clear()
      g.beginFill(ballColor, 1)
      g.drawCircle(0, 0, ball.radius)
      g.endFill()

      // remove
      if(
        ball.position.x < 0 - ball.radius || 
        ball.position.x > this.ww + ball.radius ||
        ball.position.y < 0 - ball.radius ||
        ball.position.y > this.wh + ball.radius
        ){
          this.ballCont.removeChild(ball)
        }
    }
  }


  checkCollision(){
    for(let i = 0; i < this.collisions.length; i++){
      let p = this.collisions[i]
      
      for(let j = 0; j < this.ballCont.children.length; j++){
        let ball = this.ballCont.getChildAt(j)
        let ballp = ball.position
        let distance = Math.sqrt((p.x - ballp.x) * (p.x - ballp.x) + (p.y - ballp.y) * (p.y - ballp.y))
  
        // hit
        if(distance < this.bodyColliderSize + ball.radius){
          console.log('hit: ball.life', ball.life)

          sound.playHitSE()

          let score = (ball.life > 10)? ball.life : 10
          this.gameData.score += score
          this.ballCont.removeChildAt(j)

          // effect
          this.addEffect(ballp.x, ballp.y, ball.radius)
        }
      }
    }
  }


  addEffect(x, y, r){
    let effect = new PIXI.Sprite()
    effect.position.set(x, y)
    effect.alpha = 0.3

    let g = new PIXI.Graphics()
    g.beginFill(ballColor, 1)
    g.drawCircle(0, 0, r)
    g.endFill()
    effect.addChild(g)
    this.effectCont.addChild(effect)

    // update effect count
    this.gameData.effectCount = this.effectCont.children.length
  }


  updateEffects(){
    for(let i = 0; i < this.effectCont.children.length; i++){
      let effect = this.effectCont.getChildAt(i)

      effect.scale.x += 0.05
      effect.scale.y += 0.05
      effect.alpha -= 0.01

      if(effect.alpha < 0.0){
        this.effectCont.removeChild(effect)
      }
    }
  }


  gameStart(){
    this.isGameActive = true

    this.startEnemy()
  }


  gameStop(){
    this.isGameActive = false

    this.stopEnemy()
  }


  startEnemy(){
    this.enemyInterval = setInterval(()=>{
      this.addBall()
    }, 1000)
  }


  stopEnemy(){
    clearInterval(this.enemyInterval)
  }

}

export default GameCore
