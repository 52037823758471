var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade", mode: "out-in", appear: "" } },
    [
      _vm.isShow
        ? _c("div", { staticClass: "page_loader" }, [
            _c("div", { staticClass: "inner" }, [
              _c("p", { staticClass: "loadCircleAnima" }),
              _vm._v(" "),
              _c("p", { staticClass: "text" })
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }