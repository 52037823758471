<template>
  <div id="wrap">
    <the-header />

    <div id="visual" :class="{'show': showVisual}">
      <video id="video" autoplay playsinline></video>
      <div id="pixi_container"></div>
    </div>

    <pose-detector ref="poseDetector" />

    <transition name="fade">
      <div id="scenes" v-if="isLoaded">
        <router-view @scene-callback="devideScenesCallback"></router-view>
      </div>
    </transition>
    
    <the-footer />
    <the-loader :is-show="!isLoaded" />
  </div>
</template>
<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import sleep from '../utils/Sleep'
import Utils from '../utils/Utils'
import TheHeader from '../components/TheHeader.vue'
import TheFooter from '../components/TheFooter.vue'
import TheLoader from '../components/TheLoader.vue'
import PoseDetector from '../pose/PoseDetector.vue'

const $ = require('jquery')
window.jQuery = $
window.$ = $
require('jquery.easing')

let Component = Vue.extend({

  data() {
    return {
      isLoaded: false,
      isDestroyed: false,
      showVisual: false
    }
  },

  components: {
    TheHeader,
    TheFooter,
    TheLoader,
    PoseDetector
  },

  created() {
  },

  async mounted() {

      const video = document.getElementById('video')
      this.$store.commit('setVideo', video)
      
      const canvas = document.getElementById('canvas')
      this.$store.commit('setCanvas', canvas)
      
      const $poseDetector = this.$refs.poseDetector
      await $poseDetector.start()

      this.isLoaded = true
  },

  beforeDestroy() {
    this.isDestroyed = true
  },

  destroyed(){},

  computed: {
  },

  methods: {

    devideScenesCallback(sceneId){
      console.log('devideScenesCallback')

      switch(sceneId){
        case 'intro':
          this.startPoseDetection()
          break;
        case 'startGame':
          this.startGame()
          break;
        case 'stopGame':
          this.stopGame()
          break;
        case 'loaderOn':
          this.isLoaded = true
          break;
        case 'loaderOff':
          this.isLoaded = false
          break;
      }
    },

    startPoseDetection(){
      const $poseDetector = this.$refs.poseDetector
      $poseDetector.play()
      this.showVisual = true
    },

    startGame(){
      const $poseDetector = this.$refs.poseDetector
      $poseDetector.gamePlay()
    },

    stopGame(){
      const $poseDetector = this.$refs.poseDetector
      $poseDetector.gameStop()
    }

  }

})

export default Component
</script>
