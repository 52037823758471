var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("the-header"),
      _vm._v(" "),
      _c("div", { class: { show: _vm.showVisual }, attrs: { id: "visual" } }, [
        _c("video", { attrs: { id: "video", autoplay: "", playsinline: "" } }),
        _vm._v(" "),
        _c("div", { attrs: { id: "pixi_container" } })
      ]),
      _vm._v(" "),
      _c("pose-detector", { ref: "poseDetector" }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isLoaded
          ? _c(
              "div",
              { attrs: { id: "scenes" } },
              [
                _c("router-view", {
                  on: { "scene-callback": _vm.devideScenesCallback }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("the-footer"),
      _vm._v(" "),
      _c("the-loader", { attrs: { "is-show": !_vm.isLoaded } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }