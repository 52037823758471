<template>
  <section id="pose_detector">

    <div class="setting">
      <p class="enemy">ball(transparent): {{gameData.effectCount}}</p>
      <p class="enemy">ball: {{gameData.enemyCount}}</p>
      <p class="score">score: {{gameData.score}}</p>
      <p class="logic">{{guiState.input.architecture}}</p>
      <b-button v-b-modal.modal-1 class="setting_btn">Setting</b-button>
    </div>

    <div class="statsBox" ref="statsBox" v-show="guiState.showFps"></div>

    <b-modal id="modal-1" title="PoseNet Setting" ok-only>
      <b-row>
        <b-col cols="12">

          <b-form-group label-size="sm" label="Algorithm:">
            <b-form-select size="sm" v-model="guiState.algorithm" :options="guiOptions.algorithm"></b-form-select>
          </b-form-group>

          <div class="pt-2 pb-2">
            <b-form-checkbox size="md" v-model="guiState.flipHorizontal" switch><small>FlipHorizontal</small></b-form-checkbox>
          </div>

          <div class="pt-2 pb-2">
            <b-form-checkbox size="md" v-model="guiState.showFps" switch><small>ShowFps</small></b-form-checkbox>
          </div>

          <b-form-group label-size="sm" :label="'throttle: ' + guiState.throttle">
            <b-form-input v-model="guiState.throttle" type="range" min="1" max="4" step="1"></b-form-input>
          </b-form-group>

          <div class="pb-2">
            <b-button size="sm" v-b-toggle.collapse-input block variant="light" class="text-left" aria-expanded="false" aria-controls="collapse-input">Input</b-button>
            <b-collapse id="collapse-input" class="p-3">

              <b-form-group label-size="sm" label="Architecture:">
                <b-form-select size="sm" v-model="guiState.input.architecture" :options="guiOptions.input.architecture"></b-form-select>
              </b-form-group>

              <b-form-group label-size="sm" label="OutputStride:">
                <b-form-select size="sm" v-model="guiState.input.outputStride" :options="guiOptions.input.outputStride"></b-form-select>
              </b-form-group>

              <b-form-group label-size="sm" label="InternalResolution:">
                <b-form-select size="sm" v-model="guiState.input.inputResolution" :options="guiOptions.input.inputResolution"></b-form-select>
              </b-form-group>

              <b-form-group label-size="sm" label="Multiplier:">
                <b-form-select size="sm" v-model="guiState.input.multiplier" :options="guiOptions.input.multiplier"></b-form-select>
              </b-form-group>

              <b-form-group label-size="sm" label="QuantBytes:">
                <b-form-select size="sm" v-model="guiState.input.quantBytes" :options="guiOptions.input.quantBytes"></b-form-select>
              </b-form-group>

            </b-collapse>
          </div>

          <div class="pb-2">
            <b-button size="sm" v-b-toggle.collapse-singlePoseDetection block variant="light" class="text-left" aria-expanded="false" aria-controls="collapse-singlePoseDetection">SinglePoseDetection</b-button>
            <b-collapse id="collapse-singlePoseDetection" class="p-3">

              <b-form-group label-size="sm" :label="'minPoseConfidence: ' + guiState.singlePoseDetection.minPoseConfidence">
                <b-form-input v-model="guiState.singlePoseDetection.minPoseConfidence" type="range" min="0.0" max="1.0" step="0.01"></b-form-input>
              </b-form-group>

              <b-form-group label-size="sm" :label="'minPartConfidence: ' + guiState.singlePoseDetection.minPartConfidence">
                <b-form-input v-model="guiState.singlePoseDetection.minPartConfidence" type="range" min="0.0" max="1.0" step="0.01"></b-form-input>
              </b-form-group>

            </b-collapse>
          </div>

          <div class="pb-2">
            <b-button size="sm" v-b-toggle.collapse-multiPoseDetection block variant="light" class="text-left" aria-expanded="false" aria-controls="collapse-multiPoseDetection">MultiPoseDetection</b-button>
            <b-collapse id="collapse-multiPoseDetection" class="p-3">

              <b-form-group label-size="sm" :label="'maxPoseDetections: ' + guiState.multiPoseDetection.maxPoseDetections">
                <b-form-input v-model="guiState.multiPoseDetection.maxPoseDetections" type="range" min="1" max="20"></b-form-input>
              </b-form-group>

              <b-form-group label-size="sm" :label="'minPoseConfidence: ' + guiState.multiPoseDetection.minPoseConfidence">
                <b-form-input v-model="guiState.multiPoseDetection.minPoseConfidence" type="range" min="0.0" max="1.0" step="0.01"></b-form-input>
              </b-form-group>

              <b-form-group label-size="sm" :label="'minPartConfidence: ' + guiState.multiPoseDetection.minPartConfidence">
                <b-form-input v-model="guiState.multiPoseDetection.minPartConfidence" type="range" min="0.0" max="1.0" step="0.01"></b-form-input>
              </b-form-group>

              <b-form-group label-size="sm" :label="'nmsRadius: ' + guiState.multiPoseDetection.nmsRadius">
                <b-form-input v-model="guiState.multiPoseDetection.nmsRadius" type="range" min="0" max="40"></b-form-input>
              </b-form-group>

            </b-collapse>
          </div>

          <div class="pb-2">
            <b-button size="sm" v-b-toggle.collapse-output block variant="light" class="text-left" aria-expanded="false" aria-controls="collapse-output">output</b-button>
            <b-collapse id="collapse-output" class="p-3">

              <div class="pt-2 pb-2">
                <b-form-checkbox size="md" v-model="guiState.output.showVideo" switch><small>showVideo</small></b-form-checkbox>
              </div>

              <div class="pt-2 pb-2">
                <b-form-checkbox size="md" v-model="guiState.output.showSkeleton" switch><small>showSkeleton</small></b-form-checkbox>
              </div>

              <div class="pt-2 pb-2">
                <b-form-checkbox size="md" v-model="guiState.output.showPoints" switch><small>showPoints</small></b-form-checkbox>
              </div>

              <div class="pt-2 pb-2">
                <b-form-checkbox size="md" v-model="guiState.output.showBoundingBox" switch><small>showBoundingBox</small></b-form-checkbox>
              </div>

            </b-collapse>
          </div>

          <b-form-group label-size="sm" label="Save Parameters:">
            <b-button variant="primary" size="sm" @click="saveLocalStorage">Save</b-button>
            <b-button variant="danger" size="sm" @click="deleteLocalStorage">Delete</b-button>
          </b-form-group>

        </b-col>
      </b-row>
    </b-modal>

  </section>
</template>

<script lang="js">
import Vue from 'vue'
import PoseMain from '../pose/PoseMain'
import sleep from '../utils/Sleep'
import Utils from '../utils/Utils'
import Stats from 'stats.js'
import GameCore from '../game/core'

const resnet = {
  architecture: 'ResNet50',
  outputStride: 32,
  inputResolution: 250,
  multiplier: 1.0,
  quantBytes: 2
}

const mobilenet = {
  architecture: 'MobileNetV1',
  outputStride: 16,
  inputResolution: 250,
  multiplier: 0.5,
  quantBytes: 2
}

let Component = Vue.extend({

  data() {
    return {
      video: null,
      videoSettings: null,
      game: null,
      isPlaying: false,
      isCancelUpdate: false,
      currentData: {},
      poseArray: [],
      updateCount: 0,
      gameData: {
        score: 0,
        enemyCount: 0,
        effectCount: 0
      },

      guiOptions: {
        algorithm: ['single-pose', 'multi-pose'],
        input: {
          architecture: ['ResNet50', 'MobileNetV1'],
          outputStride: [8, 16, 32],
          inputResolution: [200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800],
          multiplier: [0.50, 0.75, 1.0],
          quantBytes: [1, 2, 4]
        },
        singlePoseDetection: {},
        multiPoseDetection: {},
        output: {}
      },
      guiState: {
        algorithm: 'multi-pose',
        throttle: 1,
        input: Object.assign(mobilenet),
        singlePoseDetection: {
          minPoseConfidence: 0.1,
          minPartConfidence: 0.5
        },
        multiPoseDetection: {
          maxPoseDetections: 2,
          minPoseConfidence: 0.15,
          minPartConfidence: 0.1,
          nmsRadius: 30.0
        },
        output: {
          showVideo: true,
          showSkeleton: true,
          showPoints: true,
          showBoundingBox: true
        },
        flipHorizontal: true,
        showFps: true
      }
    }
  },

  components: {
  },

  created() {
    console.log('query', this.$route.query)

    let q = this.$route.query
    if(q){
      if(q.logic == 'resnet'){
        this.$store.commit('setLogic', 'resnet')
        this.guiState.input = Object.assign(resnet)
      }
    }

    console.log('input', this.guiState.input)
  },

  mounted() {
  },

  beforeDestroy() {
    this.isDestroyed = true
  },

  destroyed(){},

  computed: {
  },

  methods: {

    loadLocalStorage(){
      if (localStorage.getItem('poseState')) {
        try {
          this.guiState = JSON.parse(localStorage.getItem('poseState'))
        }
        catch(e) {
          localStorage.removeItem('poseState')
        }
      }
    },

    saveLocalStorage() {
      const parsed = JSON.stringify(this.guiState)
      localStorage.setItem('poseState', parsed)
    },

    deleteLocalStorage() {
      localStorage.removeItem('poseState')
    },

    async start(){
      this.video = this.$store.state.video

      this.loadLocalStorage()
      this.setupFPS()

      this.poseMain = new PoseMain(this.video)

      return new Promise(async resolve => {

        let status = this.poseMain.loadArchitecture(this.guiState)
        .then(()=>{
          console.log('poseMain Constructor: ', status, this.net)
          resolve(true)
        })
      })
    },

    // posenet start
    play(){
      this.videoSettings = this.$store.state.videoSettings

      console.log('settings', this.videoSettings)
      console.log('settings', this.$store.state.videoSettings)

      this.isPlaying = true
      this.game = new GameCore(this.video, this.videoSettings)
      this.game.updateVideo()
      this.updateStart()
    },

    // posenet stop
    stop(){
      this.video.pause()
      this.isPlaying = false
      this.updateStop()
    },

    // game start
    gamePlay(){
      this.game.gameStart()
    },

    // game finsih
    gameStop(){
      this.game.gameStop()
    },

    setupFPS(){
      let container = this.$refs.statsBox
      this.stats = new Stats()
      this.stats.showPanel(0)

      if(this.guiState.showFps) {
        let d = this.stats.dom
        d.style.position = 'fixed'
        // d.style.left = 'auto'
        // d.style.right = 0
        container.appendChild(d)
      }
    },

    updateStart(){
      this.isCancelUpdate = false
      this.update()
    },

    updateStop(){
      this.isCancelUpdate = true
    },

    async update(){
      if(!this.isCancelUpdate){

        this.stats.begin()

        if(this.updateCount % this.guiState.throttle == 0){

          // posenet
          let data = await this.poseMain.update(this.guiState)
          // await this.poseMain.draw(this.guiState, poses)

          //
          let dpose = {
            score: 0,
            keypoints: [{
              part: 'nose',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'leftEye',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'rightEye',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'leftEar',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'rightEar',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'leftShoulder',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'rightShoulder',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'leftElbow',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'rightElbow',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'leftWrist',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'rightWrist',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'leftHip',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'rightHip',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'leftKnee',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'rightKnee',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'leftAnkle',
              score: 0,
              position: {x: 0, y: 0}
            },{
              part: 'rightAnkle',
              score: 0,
              position: {x: 0, y: 0}
            }]
          }

          if(data.poses.length > 0){

            let pose = data.poses[0]
            this.poseArray.push(pose)
            if(this.poseArray.length >= 4){
              this.poseArray.shift()
            }

            for(let i = 0; i < this.poseArray.length; i++){
              let pose = this.poseArray[i]
              dpose.score += pose.score

              for(let j = 0; j < pose.keypoints.length; j++){
                dpose.keypoints[j].score += pose.keypoints[j].score
                dpose.keypoints[j].position.x += pose.keypoints[j].position.x
                dpose.keypoints[j].position.y += pose.keypoints[j].position.y
              }
            }

            let dsize = this.poseArray.length
            dpose.score /= dsize
            for(let i = 0; i < dpose.keypoints.length; i++){
              dpose.keypoints[i].score /= dsize
              dpose.keypoints[i].position.x /= dsize
              dpose.keypoints[i].position.y /= dsize
            }

            // console.log(dsize, pose.keypoints[0].position.x, dpose.keypoints[0].position.x)

            data.poses[0] = dpose
          }

          this.currentData = data
        }

        this.gameData = this.game.update(this.currentData, this.guiState)

        if(this.updateCount % 30 == 0){
      this.$store.commit('setScore', this.gameData.score)
        }

        this.stats.end()

        this.updateCount++

        requestAnimationFrame(this.update)
      }
      else{
        cancelAnimationFrame(this.update)
      }
    },

  }

})

export default Component
</script>

<style scoped lang="css">
#video{
  width: 100%;
  height: auto;
}

#canvas{
  width: 100%;
  height: auto;
  background: #f6f6f6;
}

.statsBox{
}

button[aria-expanded]:before{
  font-family: "Ionicons";
  margin-right: 5px;
}

button[aria-expanded='false']:before {
  content: '\f3d0';
}

button[aria-expanded='true']:before {
  content: '\f3d8';
}
</style>
