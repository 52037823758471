var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("the-header", {
        attrs: { "is-show-logo": true, "is-show-back": true }
      }),
      _vm._v(" "),
      _c("article", { staticClass: "common_container" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("section", { staticClass: "common_section" }, [
          _c("div", { staticClass: "text" }, [_vm._v("404 not found.")]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "btn" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Index", params: {} } } },
                [_vm._v("top page")]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("the-footer", { attrs: { "is-show-logo": false } }),
      _vm._v(" "),
      !_vm.isLoaded ? _c("the-loader") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "title_section" }, [_c("h1")])
  }
]
render._withStripped = true

export { render, staticRenderFns }