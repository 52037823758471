let bgm = new Audio()
bgm.preload = 'auto'
bgm.src = '/static/sound/bgm.mp3'
bgm.load()
bgm.volume = 0.3
bgm.loop = true

let btnSe = new Audio()
btnSe.preload = 'auto'
btnSe.src = '/static/sound/hit.mp3'
btnSe.load()
btnSe.volume = 0.3
btnSe.loop = false

let hitSe = new Audio()
hitSe.preload = 'auto'
hitSe.src = '/static/sound/hit.mp3'
hitSe.load()
hitSe.volume = 0.3
hitSe.loop = false


let SoundUtil = {

  playBGM: () => {
    bgm.play()
  },

  stopBGM: () => {
    bgm.pause()
    bgm.currenttime = 0
  },

  playBtnSE: () => {
    btnSe.currenttime = 0
    btnSe.play()
  },

  playHitSE: () => {
    hitSe.currenttime = 0
    hitSe.play()
  },

  updateBGMOption: (options) => {
    let def = {
      volume: bgm.volume
    }
    Object.assign(def, options)
    bgm.volume = options.volume
  }
}

export default SoundUtil
