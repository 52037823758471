<template>
  <section id="playing_scene" @click="togglePause">

    <div class="ui_score">
      <p class="text">とくてん</p>
      <p class="num">{{scoreView}}</p>
    </div>

    <div class="ui_time">
      <p class="text">のこりじかん</p>
      <p class="gage" :style="{'width': ((1 - currentTimePer) * 100) + '%'}"></p>
    </div>

    <transition name="fade">
      <div class="video_cover" v-if="isPause"></div>
    </transition>

  </section>
</template>

<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import sleep from '../utils/Sleep'
import {isMobile} from '../pose/demo_util'

let Component = Vue.extend({

  props: {
  },

  data() {
    return {
      duration: 30000,
      currentTime: 0,
      currentTimePer: 1.0,
      scoreView: 0,
      score: this.$store.state.score,
      isPause: false
    }
  },

  components: {
  },

  created() {
  },

  mounted() {
    this.start()
    
    this.$store.watch(
      (state, getters) => getters.score,
      (newValue, oldValue) => {
         console.log('score changed! %s => %s', oldValue, newValue)
         this.score = newValue
      }
    )
  },

  methods: {
    start(){
      this.startTime = Date.now()
      this.update()
      this.$emit('scene-callback', 'startGame')
    },

    stop(){
      this.$emit('scene-callback', 'stopGame')
      this.$router.push({path: '/finish'})
    },

    async update(){
      if(!this.isPause){
        let time = Date.now()
        this.currentTime = time - this.startTime
      }

      if(this.currentTime < this.duration){
        this.currentTimePer = this.currentTime / this.duration
        requestAnimationFrame(this.update)
      }
      else{
        await sleep(1000)
        this.stop()
      }

      if(this.score > this.scoreView){
        this.scoreView += 4
      }
      else{
        this.scoreView = this.score 
      }
    },

    togglePause(){
      this.isPause = !this.isPause
    }
  }
})

export default Component
</script>
