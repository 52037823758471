var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "finish_scene" } },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", { staticClass: "video_cover" })
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "scale-fade" } }, [
        _vm.showModal
          ? _c("div", { staticClass: "intro_modal" }, [
              _c("div", { staticClass: "inner" }, [
                _c("h1", [_vm._v("しゅうりょう！")]),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [
                  _vm._v("おつかれさまでした！"),
                  _c("br"),
                  _vm._v("きょうは" + _vm._s(_vm.score) + "ポイントだったよ。")
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "basic_btn",
                    attrs: { href: "javascript:void(0);" },
                    on: { click: _vm.go }
                  },
                  [_vm._v("HOME")]
                )
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }